import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getSinais(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/fut/sinal', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getSinaisResumo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/fut/sinal/resumo', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
